.block-solution {
  background: #fdfdfd;
}

.block-solution__list {
  display: grid;
  column-gap: 4rem;
}

.block-solution__list li {
  margin-bottom: 1rem;
  font-weight: bold;
}

@media screen and (min-width: 768px) {
  .block-solution__content {
    display: flex;
    justify-content: center;
  }

  .block-solution__list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1024px) {
  .block-solution__list {
    column-gap: 6rem;
  }
}
