/* Footer */
.footer {
  background: #232323;
  text-align: center;
  padding: 4rem 0;
}

.footer__brand {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.footer__description,
.footer__tagline,
.footer__copyright {
  color: #fff;
  opacity: 0.5;
}
