.block-contact__body {
  margin-top: 5rem;
}

.block-contact__information {
  text-align: center;
}

.block-contact__information .list {
  margin-top: 0.5rem;
}

.block-contact__information h3 {
  font-size: 2.1rem;
}

.block-contact__description {
  margin-top: 0;
  font-size: 2.2rem;
}

.block-contact__form {
  margin-top: 5rem;
}

@media screen and (min-width: 768px) {
  .block-contact__information {
    text-align: left;
  }

  .block-contact__form {
    margin-top: 0;
  }

  .block-contact__submit {
    display: block;
    width: auto;
    margin: 0 auto;
  }
}
