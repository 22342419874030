.btn {
  border: 0;
  padding: 1.75rem 3rem;
  border-radius: 50px;
  cursor: pointer;
  transition: background 0.15s, color 0.15s;
  text-align: center;
  font-weight: bold;
  white-space: nowrap;
  margin: 1rem 0;
  text-transform: uppercase;
  letter-spacing: 1px;
}

a.btn {
  display: inline-block;
}

.btn--primary {
  background: var(--color-primary);
  color: #fff;
}

.btn--primary:hover {
  background: #e91a21;
}

.btn--accent {
  background: var(--color-accent);
  color: #fff;
}

.btn--accent:hover {
  background: #17dbb4;
}

.btn--outline {
  border: 2px solid var(--color-primary);
  background: #fff;
  color: var(--color-primary);
}

.btn--outline:hover {
  background: var(--color-primary);
  color: #fff;
}

.btn--long {
  padding-left: 4.5rem;
  padding-right: 4.5rem;
}

.btn--small {
  padding: 1.25rem 3rem;
}

.btn--block {
  display: inline-block;
  width: 100%;
}
