.block-location-california__body {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.block-location-california__body img {
  border-radius: 5px;
  width: 100%;
}

.block-location-california__body img:last-of-type {
  grid-row: 2 / span 1;
  grid-column: 1 / span 2;
}

@media screen and (min-width: 768px) {
  .block-location-california__body {
    grid-template-columns: repeat(3, 1fr);
  }

  .block-location-california__body img:last-of-type {
    grid-row: 1 / span 1;
    grid-column: 3 / span 1;
  }
}
