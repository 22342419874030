.icon {
  font-size: 30px;
}

.icon--small {
  font-size: 18px;
}

.icon--medium {
  font-size: 25px;
}

.icon--primary {
  color: var(--color-primary);
}

.icon-container {
  align-items: center;
  background: var(--color-primary);
  border-radius: 100%;
  color: #fff;
  display: inline-flex;
  height: 50px;
  justify-content: center;
  width: 50px;
}
