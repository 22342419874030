nav {
  background: #fff;
  box-shadow: 0 2px 2px #e3e3e3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  flex-wrap: wrap;
}

.nav__brand {
  width: 200px;
  display: block;
}

.nav__toggler {
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.3s;
}

.nav__toggler:hover {
  opacity: 0.8;
}

.nav.collapsible--expanded .nav__toggler {
  opacity: 1;
}

.nav__list {
  width: 100%;
  margin: 0;
  /* display: none; */
}

.nav__item {
  padding: 1rem 0;
  border-bottom: 1px solid rgb(228, 228, 228);
}

.nav__item:last-of-type {
  border: 0;
  padding-bottom: 0;
}

.nav__item > a {
  font-size: 1.8rem;
  color: var(--color-body);
  transition: color 0.3s;
}

.nav__item > a:hover {
  color: var(--color-primary);
}

@media screen and (min-width: 768px) {
  .nav__toggler {
    display: none;
  }

  .nav__list {
    width: auto;
    display: flex;
    max-height: 100vh;
    opacity: 1;
    overflow: visible;
  }

  .nav__item {
    border: 0;
    margin-left: 3rem;
  }
}
