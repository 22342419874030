.block-softtelsystem {
  background: #fdfdfd;
}

.block-softtelsystem__body {
  text-align: center;
}

.block-softtelsystem .block__heading::after {
  border-bottom-color: #0097d9;
}

.block-softtelsystem__image {
  display: block;
  margin: 3rem auto 5rem;
  border-radius: 5px;
}

@media screen and (min-width: 768px) {
  .block-softtelsystem__body {
    text-align: left;
  }

  .block-softtelsystem__description {
    margin-top: 0;
  }
}
