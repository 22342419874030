.list {
  list-style: none;
  padding: 0;
}

.list--inline .list__item {
  display: inline-block;
  margin-right: 2rem;
}

.list--bullet {
  list-style-type: disc;
  padding-left: 3rem;
}

.list--bullet .list__item,
.list__item {
  margin-bottom: 1rem;
}
