.form {
  width: 100%;
}

.form__group {
  display: grid;
  margin-bottom: 2rem;
}

.form__label {
  margin-bottom: 5px;
  font-weight: bold;
}

.form__input[type='text'],
.form__input[type='email'],
.form__input[type='password'],
.form__select,
.form__textarea {
  padding: 0.6rem 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-weight: 300;
}

@media screen and (min-width: 768px) {
  .form__group {
    grid-template-columns: 200px 1fr;
  }
}
