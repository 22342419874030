.link {
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  color: var(--color-accent);
  white-space: nowrap;
  transition: border-bottom 0.1s;
}

.link--hover {
  border-bottom: 2px solid transparent;
}

.link--lowercase {
  text-transform: lowercase;
}

.link--hover:hover {
  border-bottom: 2px solid var(--color-accent);
}

.link__icon {
  transition: margin-left 0.15s;
}

.link:hover .link__icon {
  margin-left: 5px;
}

.link--softtelsystem {
  color: var(--color-softtelsystem);
}

.link--softtelsystem-hover:hover {
  border-bottom: 2px solid var(--color-softtelsystem);
}
