.block-value__body {
  column-gap: 4rem;
}

@media screen and (min-width: 768px) {
  .block-value__body > div:last-of-type {
    text-align: center;
    grid-row: 2 / span 1;
    grid-column: 1 / span 2;
  }
}

@media screen and (min-width: 1024px) {
  .block-value__body > div:last-of-type {
    grid-row: 1;
    grid-column: 1;
  }
}
