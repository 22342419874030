.collapsible__header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.collapsible__heading {
  margin-top: 0;
  font-size: 3rem;
}

.collapsible__toggler {
  transform: rotate(-90deg);
  transition: transform 0.3s;
}

.collapsible__content {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 0.3s;
}

.collapsible.collapsible--expanded .collapsible__toggler {
  transform: rotate(0deg);
}

.collapsible.collapsible--expanded .collapsible__content {
  max-height: 100vh;
  opacity: 1;
  overflow: visible;
}
