.block-service-areas {
  padding: 10rem 0 15rem;
}

.block-service-areas__list {
  display: grid;
  margin: 3rem 0;
  font-weight: bold;
  column-gap: 4rem;
}

@media screen and (min-width: 768px) {
  .block-service-areas__list {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 1rem;
  }

  .block-service-areas__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-width: 1024px) {
  .block-service-areas__list {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 6rem;
  }
}
