.block-hero {
  clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 0% 100%);
}

.block-hero__heading {
  text-shadow: 3px 3px 5px rgba(255, 255, 255, 0.2);
}

.block-hero__heading::after {
  display: none;
}

.block-hero__tagline {
  font-size: 2.1rem;
  margin: 3rem 0;
  color: #b9c3cf;
}

.block-hero__image {
  order: -1;
  background-color: white;
  padding: 1.5rem 1rem 0;
  border-radius: 5px;
  margin: 0 auto 2rem;
  align-self: flex-start;
  max-width: 400px;
}

.block-hero__image img {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .block-hero__content {
    text-align: left;
  }

  .block-hero__image {
    order: 1;
    align-self: center;
    margin-left: 5rem;
    max-width: 100%;
  }
}
