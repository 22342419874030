html {
  font-size: 62.5%; /* 10px */
}

body {
  color: var(--color-body);
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 2rem;
  line-height: 1.5;
}

h1,
h2,
h3 {
  /* color: var(--color-primary); */
  margin-bottom: 1rem;
  line-height: 1.1;
}

h1 {
  font-size: 4.5rem;
}

h2 {
  font-size: 3.5rem;
}

h3 {
  font-size: 2.7rem;
  font-weight: 500;
}

@media screen and (min-width: 768px) {
  body {
    font-size: 1.8rem;
  }

  h1 {
    font-size: 4rem;
  }

  h2 {
    font-size: 3rem;
  }

  h3 {
    font-size: 2.5rem;
  }
}
